
import { ref, watch } from 'vue';
import moment from 'moment';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import tableHeader from '@/core/data/car/partnerCarHeader';
import ApiService from '@/core/services/ApiService';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'PartnerCarLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Partner Car', ['Partner Car Lists']];
    const loopers = ['plate', 'no', 'typeName', 'brandName', 'modelName'];

    const customProperties = [
      { key: 'typeName', value: ['type', 'name'] },
      { key: 'brandName', value: ['brand', 'name'] },
      { key: 'modelName', value: ['model', 'name'] },
    ];

    const tableRef = ref<any>(null);
    const tableData = ref<any>([]);
    const loading = ref(true);
    const filterData = ref<any>([]);

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        loading.value = true;

        const funcData = await ApiService.get(
          `car?page=${page}&pageSize=${rows}`
        );
        tableData.value = funcData.data.datas;
        tableRef.value.total = funcData.data.total;

        reinitializeComponents();
        loading.value = false;
      }
    );

    return {
      tableHeader,
      breadcrumbs,
      loopers,
      customProperties,
      moment,
      tableRef,
      tableData,
      loading,
      filterData,
    };
  },
};
