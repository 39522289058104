const tableHeader = [
  { key: 'no', name: 'No.', sortable: true },
  { name: 'Type', key: 'typeName', sortable: true },
  { name: 'Brand', key: 'brandName', sortable: true },
  { name: 'Model', key: 'modelName', sortable: true },
  { name: 'License', key: 'plate', sortable: true },
  { name: 'Date Created', key: 'createdAt', sortable: true },
  { name: 'Status', key: 'verifyState', sortable: true },
  { name: 'Car Details', key: 'details', sortable: false },
];

export default tableHeader;
